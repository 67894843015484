import { gql } from '@apollo/client'

export const CAREGIVER_SEARCH_WITH_CLOCK_FILTERS = gql`
  query($data: CaregiverSearchWithClockFiltersInput!) {
    caregiverSearchWithClockFilters(data: $data) {
      count
      results {
        id
        sfContact {
          firstName
          lastName
          id
          employeeNumber
          email
          phone
          language
          sfCoordinator {
          fullName
          email
          user {
            id
            firstName
            lastName
          }
        }
        sfAccountContactRelation {
          allowClockIn
          roleType
          sfAccount {
              firstName
              lastName
              id
              cdpapCoordinator {
                fullName
              }
          }
        }
        }
        Clock {
          id
        }
      }
    }
  }
`

export const CAREGIVER_IDS_BY_CLOCK_FILTERS = gql`
  query($data: CaregiverIdsByClockFiltersInput!) {
    caregiverIdsByClockFilters(data: $data)
  }
`

export const CAREGIVERS_LATEST_CLOCK_IN = gql`
query($caregiverIds: [ID!]!) {
  caregiversLatestClockIn(caregiverIds: $caregiverIds) {
    caregiverId
    clockIn
  }
}
`

export const CAREGIVERS = gql`
  query($data: CaregiverQuery!) {
    caregivers(data: $data) {
      count
      results {
        id
        email
        profilePicPath
        sfContact {
          firstName
          lastName
          id
          externalId
          phone
          paStatus
          paType
          caregiverType
          employeeNumber
          sfAccountContactRelation {
            allowClockIn
            sfAccount {
                firstName
                lastName
                id
            }
          }
        }
      }
    }
  }
`

export const RESET_CAREGIVER_PASSWORD = gql`
  mutation($caregiverId: ID!, $password: String!) {
    resetCaregiverPassword(caregiverId: $caregiverId, password: $password) {
        id
        firstName
        lastName
        email
        sfContact {
          paStatus
          paType
          caregiverType
          createdDate
          sfAccountContactRelation {
            allowClockIn
            sfAccount {
                firstName
                lastName
                id
            }
          }
        }
    }
  }
`

import React from 'react'
import { createRoot } from 'react-dom/client'
import { SnackbarProvider } from 'notistack'
import { ApolloProvider } from '@apollo/client'
import { createClient } from 'src/utils'
import { SettingsProvider } from 'src/contexts/SettingsContext'
import { AuthProvider } from 'src/contexts/JWTAuthContext'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'react-perfect-scrollbar/dist/css/styles.css';

const client = createClient()
const container = document.getElementById('root');
const root = createRoot(container);

const renderApp = () => {
  root.render(
    <SnackbarProvider maxSnack={3}>
      <SettingsProvider>
        <ApolloProvider client={client}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ApolloProvider>
      </SettingsProvider>
    </SnackbarProvider>
  );
}

renderApp()

serviceWorker.unregister()

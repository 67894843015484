import { gql } from '@apollo/client'

export const GET_CLOSURE_DATES = gql`
query {
  closureDates {
    id
    description
    message
    month
    day
    year
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    startTime
    endTime
  }
}
`;

export const CREATE_CLOSURE_DATE = gql`
  mutation($data: CreateClosureDateInput!) {
    createClosureDate(data: $data) {
      id
      description
      message
      month
      day
      year
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      startTime
      endTime
    }
}`

export const DELETE_CLOSURE_DATE = gql`
mutation($id: ID!) {
  deleteClosureDate(id: $id) {
    id
    description
    message
    month
    day
    year
    monday
    tuesday
    wednesday
    thursday
    friday
    saturday
    sunday
    startTime
    endTime
  }
}`

export const UPDATE_CLOSURE_DATE = gql`
  mutation($id: ID!, $data: UpdateClosureDateInput!) {
    updateClosureDate(id: $id, data: $data) {
      id
      description
      message
      month
      day
      year
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      startTime
      endTime
    }
}`

import { gql } from '@apollo/client'

export const SFCAREGIVERS = gql`
  query($data: SFCaregiverQuery!) {
    sfCaregivers(data: $data) {
      count
      results {
        id
        sfid
        employeeNumber  
        firstName
        lastName
        email
        paStatus
        paType
        caregiverType
        phone
        caregiver {
          Clock {
            clockIn
          }
        }
        sfCoordinator {
          fullName
          email
          user {
            id
            firstName
            lastName
          }
        }
        sfAccountContactRelation {
          allowClockIn
          roleType
          sfAccount {
              firstName
              lastName
              id
          }
        }
      }
    }
  }
`

export const SFCAREGIVER = gql`
  query($id: ID!) {
    sfCaregiver(id: $id) {
      id
      sfid
      employeeNumber  
      firstName
      lastName
      email
      paStatus
      paType
      caregiverType
      createdDate
      activeDate
      terminatedDate
      address
      phone
      caregiverRegistrations {
        registrationCode
        expiresAt
        phone
      }
      caregiver {
        id
        email
        profilePicPath
        ruleGroupId
        usesCustomRules
        customRules{
          id
          createdAt
          updatedAt
          action
          restriction
          restrictionValue
        }
      }
      sfAccountContactRelation {
        allowClockIn
        isActive
        sfContact {
          caregiver {
            id
          }
          firstName
          lastName
          externalId
      }
        sfAccount {
            firstName
            lastName
            id
            sfid
            sfExternalId
            sfAuthorizations{
              id
              authStartDate
              authEndDate
              hoursPerWeek
            }
        }
      }
    }
  }
`
export const PA_STATUS_TYPES = gql`
  query{
    paStatusTypes
  }
`

import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import {
  ThemeProvider, StyledEngineProvider, CssBaseline
} from '@mui/material';
import { Theme } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ScrollReset from 'src/components/ScrollReset'
import routes, { renderRoutes } from 'src/routes'
import { createTheme } from './theme'
import useSettings from './hooks/useSettings'
import useAuth from './hooks/useAuth'
import { addUserFeedbackScript } from './utils/system'
import { ErrorBoundaryComponent } from './components/ErrorBoundary';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

// const history = createBrowserHistory()

const App = () => {
  const { settings } = useSettings()
  const auth = useAuth()

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      addUserFeedbackScript()
    }
  }, [auth.isAuthenticated])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollReset />
          <CssBaseline />
          <ErrorBoundaryComponent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {renderRoutes(routes)}
            </LocalizationProvider>
          </ErrorBoundaryComponent>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App

import React from 'react'
import { ErrorBoundary } from 'react-error-boundary';
import {
  Button, Typography, Container, Box
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useHistory } from 'react-router-dom';

function ErrorFallback() {
  const history = useHistory();

  const handleGoHome = () => {
    history.push('/'); // Navigate to the home page
    window.location.reload()
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      {/* Error Icon */}
      <ErrorOutlineIcon
        color="error"
        sx={{ fontSize: 80, mb: 3 }} // Large size and margin below the icon
      />

      {/* Error Text */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Something went wrong
        </Typography>
        <Typography variant="body1">
          Please contact your admin.
        </Typography>
      </Box>

      {/* Go Home Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoHome}
        sx={{ mt: 3 }}
      >
        Go to Home Page
      </Button>
    </Container>
  );
}

export const ErrorBoundaryComponent = ({ children }: { children: any }) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
)
